import React, { useEffect, useRef, useState } from 'react';
import { appAssets } from 'constants/assets';
import { BaseWidget } from 'models/WidgetModel';
import './CorporatePulse.scss';
import { CorporatePulseModel } from 'models/CorporatePulseModel';
import { sumPropertyValues } from 'helpers/utilities';
import { useStore } from 'store/useGlobalStore';

type CorporatePulseProps = BaseWidget;
interface StatValues {
  previous: number;
  current: number;
  pLabel: string;
  cLabel: string;
  delta: number | null;
}

const statValuesDefault = {
  previous: 0,
  current: 0,
  pLabel: 'Previous Month',
  cLabel: 'Current Month',
  delta: null,
};

const CorporatePulse: React.FC<CorporatePulseProps> = ({ response }) => {
  const [keyString, selectKey] = useState('');
  const [corporatePulseStats, setCorporatePulseStats] = useState<CorporatePulseModel[]>([]);
  const [corporatePulseBottomStats, setCorporatePulseBottomStats] = useState<CorporatePulseModel[]>(
    []
  );
  const [isPercentage, setIsPercentage] = useState<boolean>(true);
  const [columnName, setColumnName] = useState<string>('');
  const cardContainer = useRef<HTMLInputElement>(null);
  const scroll = useRef<HTMLInputElement>(null);
  const [showScroll, setShowScroll] = useState<boolean>(false);

  const [highLights, setHighlights] = useState<StatValues>(statValuesDefault);
  const [lowlight, setLowlights] = useState<StatValues>(statValuesDefault);

  const widgets = useStore((state) => state.widgets);

  const showIcon = (delta: number) => {
    const TrendingFlat = appAssets.icons.TRENDING_FLAT;
    const TrendingUp = appAssets.icons.TRENDING_UP;
    const TrendingDown = appAssets.icons.TRENDING_DOWN;

    if (delta == 0 || delta == null) {
      return <TrendingFlat></TrendingFlat>;
    } else if (delta.toString() == 'NotMapped') {
      return '';
    } else if (delta > 0) {
      return <TrendingUp></TrendingUp>;
    } else if (delta < 0) {
      return <TrendingDown></TrendingDown>;
    }
  };

  const showPositive = (delta: number) => {
    if (delta > 0) {
      return '+';
    }
  };

  useEffect(() => {
    if (response?.data) {
      response.data.corporatePulseBottomStats.length === 0 &&
      response.data.corporatePulseStats.length > 0
        ? setCorporatePulseBottomStats(populateWithZeros(response.data.corporatePulseStats))
        : setCorporatePulseBottomStats(response.data.corporatePulseBottomStats);
      response.data.corporatePulseStats.length === 0 &&
      response.data.corporatePulseBottomStats.length > 0
        ? setCorporatePulseStats(populateWithZeros(response.data.corporatePulseBottomStats))
        : setCorporatePulseStats(response.data.corporatePulseStats);
      if (response.data.isPercentage != undefined) setIsPercentage(response.data.isPercentage);
      setColumnName(response.data.columnLabel);

      // Checking and setting labels
      setLabels(response.data.corporatePulseStats, setHighlights, response.data);
      setLabels(response.data.corporatePulseBottomStats, setLowlights, response.data);
    }
  }, [response]);

  useEffect(() => {
    setScroll();
  }, [corporatePulseBottomStats, corporatePulseStats, widgets]);

  const populateWithZeros = (models: CorporatePulseModel[]): any => {
    return models.map((item: any) => ({
      ...item,
      current: '0.0',
      delta: 'NotMapped',
      previous: '0.0',
    }));
  };

  const setLabels = (
    cp: CorporatePulseModel[],
    setStateValues: React.Dispatch<React.SetStateAction<StatValues>>,
    data: any
  ) => {
    if (cp) {
      const previous = sumPropertyValues(cp, 'previous');
      const current = sumPropertyValues(cp, 'current');
      const delta = sumPropertyValues(cp, 'delta');
      let cLabel = 'Current Month';
      let pLabel = 'Previous Month';

      if (previous > 0 && data?.previousLabel) {
        pLabel = data?.previousLabel;
      }

      if (current > 0 && data?.currentLabel) {
        cLabel = data?.currentLabel;
      }

      if (data?.timePeriodType === 'Quarterly') {
        pLabel = 'Previous Quarter';
        cLabel = 'Current Quarter';
      } else if (data?.timePeriodType === 'Monthly') {
        pLabel = 'Previous Month';
        cLabel = 'Current Month';
      } else if (data?.timePeriodType === 'Yearly') {
        pLabel = 'Previous Year';
        cLabel = 'Current Year';
      } else {
        pLabel = 'Previous Month';
        cLabel = 'Current Month';
      }

      setStateValues({
        previous,
        current,
        cLabel,
        pLabel,
        delta,
      });
    }
  };

  // return first 200 characters of the description
  const getDescription = (description: string, key: number) => {
    if (description.length <= 95) return description;
    const CloseIcon = appAssets.icons.CLOSE;

    const short = description.slice().substring(0, 95);
    return (
      <>
        {keyString === `key-${key}` ? (
          <div id={`show-${key}`} className="pck-modal open" onClick={() => selectKey('')}>
            <div className="modal-box">
              <div className="modal-header">
                <div className="title">
                  {columnName} &nbsp;
                  <div className="close-btn">
                    <CloseIcon />
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="text">{description}</div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="long-label" onClick={() => selectKey(`key-${key}`)}>
          {short}...
          <span className="view-more">show more</span>
        </div>
      </>
    );
  };

  const setScroll = () => {
    if (cardContainer.current && scroll.current) {
      setShowScroll(scroll.current.clientHeight > cardContainer.current.clientHeight);
    }
  };

  window.addEventListener('resize', setScroll);

  return (
    <div
      className={`corporatePulse ${showScroll ? 'sc-container' : ''}`}
      data-testid="CorporatePulse"
      ref={cardContainer}
    >
      <div ref={scroll}>
        {corporatePulseStats?.length ? (
          <>
            <div className="row">
              <div className="cp-subtitle">Highlights</div>
            </div>
            <div className="cp-row-header">
              <div className="text-justify">{columnName}</div>
              {highLights?.previous >= 0 ? <div>{highLights?.pLabel}</div> : null}
              {highLights?.current >= 0 ? <div>{highLights?.cLabel}</div> : null}
              <div></div>
            </div>
            {corporatePulseStats?.map((stats, key) => {
              return (
                <div key={key} className="cp-row cp-text">
                  <div>{getDescription(stats.label, key)}</div>
                  {highLights?.previous >= 0 ? (
                    <div>
                      {stats.previous} {isPercentage ? '%' : ''}
                    </div>
                  ) : null}
                  {highLights?.current >= 0 ? (
                    <div>
                      {stats.current} {isPercentage ? '%' : ''}
                    </div>
                  ) : null}
                  {highLights?.delta !== null ? (
                    <div className="dashbd-exec-delta-text">
                      <span className="dashbd-delta-numb-green numb-blue">
                        {showPositive(stats.delta)}
                        {stats.delta != null
                          ? stats.delta.toString() == 'NotMapped'
                            ? ''
                            : stats.delta
                          : ''}
                      </span>
                      {showIcon(stats.delta)}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
          </>
        ) : null}
        {corporatePulseBottomStats?.length ? (
          <>
            <div className="row heaader-padding margin-top-10px">
              <div className="cp-subtitle text-left">Lowlights</div>
            </div>
            <div className="row cp-row-header">
              <div className="text-justify">{columnName}</div>
              {lowlight?.previous >= 0 ? <div>{lowlight?.pLabel}</div> : null}
              {lowlight?.current >= 0 ? <div>{lowlight?.cLabel}</div> : null}
              <div></div>
            </div>

            {corporatePulseBottomStats?.map((stats, key) => {
              return (
                <div key={key} className="cp-row cp-text">
                  <div>{getDescription(stats.label, key)}</div>
                  {lowlight?.previous >= 0 ? (
                    <div>
                      {stats.previous} {isPercentage ? '%' : ''}
                    </div>
                  ) : null}
                  {lowlight?.current >= 0 ? (
                    <div>
                      {stats.current} {isPercentage ? '%' : ''}
                    </div>
                  ) : null}
                  {lowlight?.delta !== null ? (
                    <div className="dashbd-exec-delta-text">
                      <span className="dashbd-delta-numb-green numb-blue">
                        {showPositive(stats.delta)}
                        {stats.delta != null
                          ? stats.delta.toString() == 'NotMapped'
                            ? ''
                            : stats.delta
                          : ''}
                      </span>
                      {showIcon(stats.delta)}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CorporatePulse;
